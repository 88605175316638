import {IHeadingData} from "@/app/models/wp";
import React, {SyntheticEvent} from "react";

export default function WPTOC({TOC}: {TOC: IHeadingData[] }) {
    function scrollToIdWithOffset(id: string, event: SyntheticEvent) {
        event.preventDefault()
        const element = document.getElementById(id)
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY
            const offsetPosition = elementPosition - 100

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }
    }

    return <>
        <div className='border rounded-xl border-gray-100 px-2 py-2 hidden lg:flex lg:flex-col'>
            <nav
                className='overflow-x-hidden scroll-smooth relative px-1 py-1 transition-all md:max-h-[235px] custom-scrollbar toc-content'
            >
                <ul className='pl-6 pr-2 flex flex-col'>
                    {TOC.map((section, index) => (
                        <li key={index} className='relative pb-1 mb-1 hover:underline hover:cursor-pointer'>
                            <a
                                href={`#${section.id}`}
                                className='hover:underline'
                                onClick={(event) => scrollToIdWithOffset(section.id, event)}
                            >
                                {section.text}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    </>
}
